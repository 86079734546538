import React from 'react'
import PropTypes from 'prop-types'
import { renderRoutes } from 'react-router-config'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import routes from '../routes'

import theme from '../definitions/theme'
import AlertProvider from '../modules/alert/containers/AlertProvider'

const App = ({ store, history }) => (
  <Provider store={store}>
    <CssBaseline />
    <BrowserRouter>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>{renderRoutes(routes)}</ThemeProvider>
      </ConnectedRouter>
    </BrowserRouter>
    <AlertProvider />
  </Provider>
)

App.propTypes = {
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
}

export default App
