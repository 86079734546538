import React from 'react'
import ReactDOM from 'react-dom'
import { call } from 'redux-saga/effects'
import App from '../../components/App'

export default function* render({ store, history }) {
  yield call(
    [ReactDOM, 'render'],
    <App store={store} history={history} />,
    document.getElementById('root'),
  )
}
