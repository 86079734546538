/* eslint-disable react/prop-types */
import React from 'react'
import processRoutes from './helper'
import Firewall from '../modules/security/containers/Firewall'
import Layout from '../components/Layout'

const Login = () => (
  <div>
    <h2>Login</h2>
  </div>
)

const NotFound = () => (
  <div>
    <h3>NotFound</h3>
  </div>
)

const routes = [
  {
    component: Firewall,
    routes: [
      {
        path: '/app',
        component: Layout,
        routes: [
          {
            path: '/clients',
            title: 'Clients',
            load: () => import('./ClientListPage.js'),
          },
          {
            path: '/clients/add',
            title: 'New client',
            load: () => import('./ClientPage.js'),
          },
          {
            path: '/clients/:id',
            title: 'Client details',
            load: () => import('./ClientPage.js'),
          },
          {
            path: '/clients/:clientId/keys',
            title: 'Keys details',
            load: () => import('./KeysPage.js'),
          },
        ],
      },
      {
        path: '/login',
        component: Login,
      },
    ],
  },
]

export default processRoutes({
  routes,
  notFoundComponent: NotFound,
})
