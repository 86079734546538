import { connect } from 'react-redux'
import { push } from 'connected-react-router'

const RouterProvider = ({ goTo, render }) => {
  return render({ goTo })
}

const mapDispatchToProps = dispatch => ({
  goTo: url => dispatch(push(url)),
})

const mapStateToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(RouterProvider)
