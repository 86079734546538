import api from '../../api'

/* eslint-disable import/prefer-default-export */

export const createNewClient = ({ name }) =>
  api({
    url: '/Company',
    method: 'POST',
    data: { name },
  })

export const updateClient = ({ id, name }) =>
  api({
    url: '/Company',
    method: 'PUT',
    data: { id, name },
  })

export const fetchClient = ({ id }) =>
  api({
    url: `/Company/${id}`,
    method: 'GET',
  })

export const fetchClientList = ({ take = 5, skip = 0 }) =>
  api({
    url: `/Company/list?take=${take}&skip=${skip}`,
    method: 'GET',
  })

export const fetchClientKeys = ({ clientId, take = 5, skip = 0 }) =>
  api({
    url: `/Key/list/${clientId}?take=${take}&skip=${skip}`,
    method: 'GET',
  })

export const createClientKey = ({
  reads,
  writes,
  instances,
  clientId: companyId,
  googleKey = '',
}) =>
  api({
    url: `/Key`,
    method: 'POST',
    data: { companyId, reads, writes, instances, googleKey },
  })

export const updateClientKey = ({
  id,
  reads,
  writes,
  instances,
  googleKey = '',
}) =>
  api({
    url: `/Key`,
    method: 'PUT',
    data: { id, reads, writes, instances, googleKey },
  })
