import produce from 'immer'

/* eslint-disable import/prefer-default-export */

export const startRequestReducer = (loader = 'isLoading') =>
  produce(draft => {
    draft.ui[loader] = true
  })

export const endRequestReducer = (loader = 'isLoading') =>
  produce(draft => {
    draft.ui[loader] = false
  })
