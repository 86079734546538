import { createActions } from 'redux-actions'

/**
 * Default state
 */
export const defaultState = {}

/**
 * Action types
 */
export const RESET_STATE = 'RESET_STATE'

/**
 * Action creators
 */
export const { runApp, resetState } = createActions({
  RUN_APP: ({ store, history }) => ({ store, history }),
  [RESET_STATE]: undefined,
})
