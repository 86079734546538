import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { RESET_STATE } from '../duck'
import reducers from './reducers'

const createRootReducer = history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    ...reducers,
  })

  return function rootReducer(state, action) {
    let newState = state

    // If RESET_STATE action is dispatched
    if (action.type === RESET_STATE) {
      // Create an empty state and keep the given reducers
      newState = {
        router: state.router,
      }
    }

    return appReducer(newState, action)
  }
}

export default createRootReducer

// const appReducer = combineReducers(reducers)

// function rootReducer(state, action) {
//   let newState = state

//   // If RESET_STATE action is dispatched
//   if (action.type === RESET_STATE) {
//     // Create an empty state and keep the given reducers
//     newState = {
//       router: state.router,
//     }
//   }

//   return appReducer(newState, action)
// }

// export default appReducer
