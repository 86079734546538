import { createBrowserHistory } from 'history'
import * as serviceWorker from './serviceWorker'
import configureStore from './store/configureStore'
import { runApp, defaultState as initialState } from './duck'

// create History object
const history = createBrowserHistory()

// Configure redux store
const store = configureStore({ initialState, history })

store.dispatch(runApp({ store, history }))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
