import loadable from '@loadable/component'

export function extractPathsFormRoutes(routes = []) {
  let paths = {}

  routes.forEach(item => {
    if (item.key && item.path) {
      paths[item.key] = item.path
    }

    if (item.routes && item.routes.length) {
      const childrenPath = extractPathsFormRoutes(item.routes)
      const newPaths = { ...paths, ...childrenPath }

      paths = newPaths
    }
  })

  return paths
}

export default function processRoutes({
  routes = [],
  path = '',
  notFoundComponent,
}) {
  const formatedRoutes = routes.map(route => {
    const item = { ...route }

    // formatPath
    if (item.path) {
      item.path = path + route.path
    }

    item.exact = Boolean((!item.routes || !item.routes.length) && item.path)

    // item.exact = Boolean(item.routes && item.routes.length)
    if (item.routes && item.routes.length) {
      item.routes = processRoutes({
        routes: item.routes,
        path: item.path,
        notFoundComponent,
      })

      // add notFoundRoute
      if (notFoundComponent) {
        item.routes.push({
          path: '*',
          component: notFoundComponent,
        })
      }
    }

    // load chuck if has load instead of component
    if (item.load) {
      item.component = loadable(item.load)
    }

    return item
  })

  return formatedRoutes
}
