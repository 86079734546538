import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import AccountIcon from '@material-ui/icons/AccountCircle'
import useStyles from './style'

const TopBar = ({ title, open, onOpen }) => {
  const classes = useStyles()

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => onOpen(!open)}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {title}
        </Typography>
        <IconButton color="inherit">
          <AccountIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
}

TopBar.defaultProps = {
  title: '',
}

export default TopBar
