/* eslint-disable import/prefer-default-export */

export const getClientsLoading = state => state.clients.ui.loading

export const getKeyHandlerLoading = state =>
  state.clients.ui.isKeyHandlerLoading

export const getFetchClientKeysLoading = state =>
  state.clients.ui.isFetchKeysLoading

export const getFetchClientLoading = state =>
  state.clients.ui.isFetchClientLoading

export const getClientList = state => state.clients.list

export const getCurrentClient = state => state.clients.currentClient

export const getKeyList = state => state.clients.keys

export const getPagination = state => state.clients.pagination
