import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { renderRoutes, matchRoutes } from 'react-router-config'
import { Container } from '@material-ui/core'
import TopBar from '../TopBar'
import useStyles from './style'
import MainMenu from '../MainMenu/MainMenu'

/* eslint-disable react/forbid-prop-types */

const Layout = ({ route, location }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const { title } = matchRoutes(route.routes, location.pathname)[0].route

  return (
    <div className={classes.root}>
      <TopBar title={title} open={open} onOpen={status => setOpen(status)} />

      <MainMenu open={open} onOpen={status => setOpen(status)} />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {renderRoutes(route.routes)}
        </Container>
      </main>
    </div>
  )
}

Layout.propTypes = {
  route: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
