import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension' // eslint-disable-line import/no-extraneous-dependencies

import { routerMiddleware } from 'connected-react-router'
import { name, version } from '../../package.json'
import rootSaga from '../saga/rootSaga'
import createRootReducer from './rootReducer'

const isDev = process.env.NODE_ENV === 'development'

export default function configureStore({ initialState, history }) {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware()

  // set an array of middlewares for the enhancer
  const middleware = [sagaMiddleware, routerMiddleware(history)]

  const composeEnhancers = isDev
    ? composeWithDevTools({
        name: `${name}@${version}`,
      })
    : compose

  const enhancer = composeEnhancers(applyMiddleware(...middleware))
  const store = createStore(createRootReducer(history), initialState, enhancer)

  // run saga
  sagaMiddleware.run(rootSaga)

  return store
}
