import { createActions, handleActions } from 'redux-actions'
import produce from 'immer'

/**
 * Default state
 */
export const defaultState = {
  message: '',
  variant: 'success',
  isVisible: false,
}

/**
 * Action creators
 */
export const { alertShow, alertClear } = createActions({
  ALERT_SHOW: ({ message, variant = 'success' }) => ({ message, variant }),
  ALERT_CLEAR: undefined,
})

/**
 * reducer
 */
export default handleActions(
  new Map([
    [
      alertShow,
      produce((draft, action) => {
        draft.message = action.payload.message
        draft.variant = action.payload.variant
        draft.isVisible = true
      }),
    ],
    [
      alertClear,
      produce(draft => {
        draft.isVisible = false
      }),
    ],
  ]),
  defaultState,
)
