import { renderRoutes } from 'react-router-config'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { getAppPath } from '../links'
import { getClientsPath } from '../../Clients/links'

const appPath = getAppPath()
const clientsPath = getClientsPath()

const Firewall = ({ route, location, redirectTo }) => {
  const { pathname } = location

  const isRootPath = pathname === '/'
  const isAppPath = pathname === appPath

  if (isRootPath || isAppPath) {
    redirectTo(clientsPath)
    return null
  }

  return renderRoutes(route.routes)
}

const mapDispatchToProps = dispatch => ({
  redirectTo: url => dispatch(push(url)),
})

const mapStateToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(Firewall)
