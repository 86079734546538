import { all, take, put, call, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  clientsCreateClient,
  clientsRequestEnd,
  clientsRequestStart,
  clientsFetchClient,
  clientsSetCurrentClient,
  clientsFetchRequestStart,
  clientsFetchRequestEnd,
  clientsUpdateClient,
  clientsFetchKeys,
  clientsFetchKeysRequestEnd,
  clientsFetchKeysRequestStart,
  clientsCreateKey,
  clientsUpdateKey,
  clientsSetPagination,
  clientsKeyHandlerRequestStart,
  clientsKeyHandlerRequestEnd,
  clientsFetchClientList,
  clientsSetClientList,
  clientsSetKeys,
} from './duck'
import {
  createNewClient,
  fetchClient,
  updateClient,
  fetchClientKeys,
  createClientKey,
  updateClientKey,
  fetchClientList,
} from './api'
import { getClientsPath } from './links'
import { alertShow } from '../alert/duck'
import {
  getClientList,
  getCurrentClient,
  getPagination,
  getKeyList,
} from './selectors'

function* fetchClientListFlow() {
  while (true) {
    const { payload } = yield take(clientsFetchClientList)

    try {
      yield put(clientsFetchRequestStart())

      const storePagination = yield select(getPagination)

      const isInit =
        payload.isInit || storePagination.rowsPerPage !== payload.rowsPerPage

      const page = isInit ? 0 : payload.page

      const { rowsPerPage } = payload

      const skip = rowsPerPage * page

      const storeList = yield select(getClientList)

      let { count } = storePagination

      if (isInit || (!isInit && page === storeList.length / rowsPerPage)) {
        const { data, headers } = yield call(fetchClientList, {
          take: rowsPerPage,
          skip,
        })

        count = isInit ? Number(headers['x-total-count']) : count

        const list = isInit ? data : [...storeList, ...data]

        yield put(clientsSetClientList(list))
      }

      const pagination = { page, rowsPerPage, count }

      yield put(clientsSetPagination(pagination))
    } catch (error) {
      yield put(push(getClientsPath()))
      yield put(alertShow({ message: 'Uknown Error', variant: 'error' }))
    } finally {
      yield put(clientsFetchRequestEnd())
    }
  }
}

function* fetchClientFlow() {
  while (true) {
    const { payload } = yield take(clientsFetchClient)
    const id = yield Number(payload.id)

    try {
      yield put(clientsFetchRequestStart())

      const currentCLient = yield select(getCurrentClient)

      const currentClientId = Number(currentCLient.id)

      if (!currentCLient || currentClientId !== id) {
        const clients = yield select(getClientList)

        let item = clients.find(elem => elem.id === payload.id)

        if (!item) {
          const { data } = yield call(fetchClient, { id })
          item = data
        }

        yield put(clientsSetCurrentClient({ ...item, id }))
      }
    } catch (error) {
      yield put(push(getClientsPath()))
      yield put(
        alertShow({ message: "Ce client n'exist pas", variant: 'error' }),
      )
    } finally {
      yield put(clientsFetchRequestEnd())
    }
  }
}

function* fetchClientKeysFlow() {
  while (true) {
    const { payload } = yield take(clientsFetchKeys)

    try {
      yield put(clientsFetchKeysRequestStart())

      const storePagination = yield select(getPagination)

      const isInit =
        payload.isInit || storePagination.rowsPerPage !== payload.rowsPerPage

      const page = isInit ? 0 : payload.page

      const { rowsPerPage } = payload

      const skip = rowsPerPage * page

      const storeKeyList = yield select(getKeyList)

      let { count } = storePagination

      if (isInit || (!isInit && page === storeKeyList.length / rowsPerPage)) {
        const currentClient = yield select(getCurrentClient)

        const { data, headers } = yield call(fetchClientKeys, {
          clientId: currentClient.id,
          take: rowsPerPage,
          skip,
        })

        count = isInit ? Number(headers['x-total-count']) : count

        const keyList = isInit ? data : [...storeKeyList, ...data]

        yield put(clientsSetKeys(keyList))
      }

      const pagination = { page, rowsPerPage, count }

      yield put(clientsSetPagination(pagination))
    } catch (error) {
      yield put(push(getClientsPath()))
      yield put(
        alertShow({ message: "Ce client n'exist pas", variant: 'error' }),
      )
    } finally {
      yield put(clientsFetchKeysRequestEnd())
    }
  }
}

function* clientKeyHandlerFlow() {
  while (true) {
    const action = yield take([clientsCreateKey, clientsUpdateKey])

    const { payload } = action

    yield put(clientsKeyHandlerRequestStart())

    try {
      switch (action.type) {
        // update key
        case clientsUpdateKey.toString(): {
          yield call(updateClientKey, payload)

          const storeKeys = yield select(getKeyList)
          const keys = storeKeys.map(item => {
            if (item.id !== payload.id) return item
            return { ...item, ...payload }
          })
          yield put(clientsSetKeys(keys))

          yield put(alertShow({ message: 'Key updated successfully' }))
          break
        }

        // create key
        default: {
          const currentClient = yield select(getCurrentClient)

          yield call(createClientKey, {
            ...payload,
            clientId: currentClient.id,
          })

          const { rowsPerPage } = yield select(getPagination)

          yield put(clientsFetchKeys({ page: 0, isInit: true, rowsPerPage }))

          yield put(alertShow({ message: 'Key created successfully' }))
          break
        }
      }
      // yield put(clientsSetCurrentClient(item))
    } catch (error) {
      yield put(alertShow({ message: 'Error', variant: 'error' }))
    } finally {
      yield put(clientsKeyHandlerRequestEnd())
    }
  }
}

function* createClientFlow() {
  while (true) {
    const { payload } = yield take(clientsCreateClient)

    try {
      yield put(clientsRequestStart())

      yield call(createNewClient, payload)

      yield put(alertShow({ message: 'Client created successfully' }))

      yield put(push(getClientsPath()))
    } catch (error) {
      yield put(alertShow({ message: 'error', variant: 'error' }))
      // handle error
    } finally {
      yield put(clientsRequestEnd())
    }
  }
}

function* updateClientFlow() {
  while (true) {
    const { payload } = yield take(clientsUpdateClient)

    try {
      yield put(clientsRequestStart())

      yield call(updateClient, payload)

      yield put(alertShow({ message: 'Client updated successfully' }))

      yield put(push(getClientsPath()))
    } catch (error) {
      yield put(alertShow({ message: 'error', variant: 'error' }))
      // handle error
    } finally {
      yield put(clientsRequestEnd())
    }
  }
}

function* rootSaga() {
  yield all([
    call(createClientFlow),
    call(fetchClientFlow),
    call(updateClientFlow),
    call(fetchClientKeysFlow),
    call(clientKeyHandlerFlow),
    call(fetchClientListFlow),
  ])
}

export default rootSaga
