import { call, take } from 'redux-saga/effects'

import beforeRender from './hooks/beforeRender'
import render from './hooks/render'
import { runApp } from '../duck'

export default function* appFlow() {
  const action = yield take(runApp)

  /**
   * Hook saga before ReactDOM.render is called
   */
  yield call(beforeRender, action.payload)

  /**
   * Hook saga where ReactDOM.render is called
   */
  yield call(render, action.payload)
}
