import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Snackbar } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { alertClear } from '../duck'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles = makeStyles(theme => ({
  success: {
    '& > div': {
      backgroundColor: '#43a047',
    },
  },
  error: {
    '& > div': {
      backgroundColor: '#d32f2f',
    },
  },
  info: {
    '& > div': {
      backgroundColor: '#1976d2',
    },
  },
  warning: {
    '& > div': {
      backgroundColor: '#ffa000',
    },
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const AlertProvider = () => {
  const dispatch = useDispatch()
  const { message, variant, isVisible } = useSelector(state => state.alert)

  const classes = useStyles()
  const Icon = variantIcon[variant]

  function handleClose() {
    dispatch(alertClear())
  }

  return (
    <Snackbar
      autoHideDuration={3000}
      classes={{ root: classes[variant] }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isVisible}
      onClose={handleClose}
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  )
}

export default AlertProvider
