import React from 'react'
import PropTypes from 'prop-types'
import {
  Drawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import PeopleIcon from '@material-ui/icons/People'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import useStyles from './style'
import { getClientsPath } from '../../modules/Clients/links'
import RouterProvider from '../../modules/security/containers/RouterProvider'

const MainMenu = ({ open, onOpen }) => {
  const classes = useStyles()
  const { pathname } = useLocation()

  return (
    <Drawer
      color="primary"
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={() => onOpen(!open)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />

      <RouterProvider
        render={({ goTo }) => (
          <List>
            <ListItem
              button
              selected={pathname === getClientsPath()}
              onClick={() => goTo(getClientsPath())}
            >
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Clients" />
            </ListItem>
          </List>
        )}
      />
    </Drawer>
  )
}

MainMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
}

export default MainMenu
