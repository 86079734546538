import { createActions, handleActions } from 'redux-actions'
import produce from 'immer'
import { endRequestReducer, startRequestReducer } from '../../duck/common'

/**
 * Default state
 */
export const defaultState = {
  currentClient: {},
  list: [],
  keys: [],
  pagination: {
    page: 0,
    rowsPerPage: 5,
    count: 0,
  },
  ui: {
    isLoading: false,
    isFetchClientLoading: false,
    isFetchKeysLoading: false,
    isKeyHandlerLoading: false,
  },
}

/**
 * Action creators
 */
export const {
  clientsCreateClient,
  clientsSetPagination,
  clientsFetchKeys,
  clientsCreateKey,
  clientsUpdateKey,
  clientsSetKeys,
  clientsSetClientList,
  clientsUpdateClient,
  clientsFetchClient,
  clientsFetchClientList,
  clientsSetCurrentClient,
  clientsRequestStart,
  clientsRequestEnd,
  clientsFetchRequestStart,
  clientsFetchRequestEnd,
  clientsFetchKeysRequestStart,
  clientsFetchKeysRequestEnd,
  clientsKeyHandlerRequestStart,
  clientsKeyHandlerRequestEnd,
} = createActions({
  CLIENTS_CREATE_CLIENT: infos => infos,
  CLIENTS_SET_PAGINATION: ({ page, rowsPerPage, count }) => ({
    page,
    rowsPerPage,
    count,
  }),
  CLIENTS_CREATE_KEY: ({ id, reads, writes, instances, googleKey }) => ({
    id,
    reads,
    writes,
    instances,
    googleKey,
  }),
  CLIENTS_UPDATE_KEY: ({ id, reads, writes, instances, googleKey }) => ({
    id,
    reads,
    writes,
    instances,
    googleKey,
  }),
  CLIENTS_UPDATE_CLIENT: infos => infos,
  CLIENTS_SET_CLIENT_LIST: list => list,
  CLIENTS_SET_CURRENT_CLIENT: infos => infos,
  CLIENTS_FETCH_CLIENT: ({ id }) => ({ id }),
  CLIENTS_FETCH_CLIENT_LIST: ({ page, rowsPerPage, isInit }) => ({
    page,
    rowsPerPage,
    isInit,
  }),
  CLIENTS_FETCH_KEYS: ({ page, rowsPerPage, isInit }) => ({
    page,
    rowsPerPage,
    isInit,
  }),
  CLIENTS_SET_KEYS: list => list,
  CLIENTS_REQUEST_START: undefined,
  CLIENTS_REQUEST_END: undefined,
  CLIENTS_FETCH_REQUEST_START: undefined,
  CLIENTS_FETCH_REQUEST_END: undefined,
  CLIENTS_FETCH_KEYS_REQUEST_START: undefined,
  CLIENTS_FETCH_KEYS_REQUEST_END: undefined,
  CLIENTS_KEY_HANDLER_REQUEST_START: undefined,
  CLIENTS_KEY_HANDLER_REQUEST_END: undefined,
})

/**
 * reducer
 */
export default handleActions(
  new Map([
    [clientsRequestStart, startRequestReducer()],
    [clientsRequestEnd, endRequestReducer()],
    [clientsFetchRequestStart, startRequestReducer('isFetchClientLoading')],
    [clientsFetchRequestEnd, endRequestReducer('isFetchClientLoading')],
    [clientsFetchKeysRequestStart, startRequestReducer('isFetchKeysLoading')],
    [clientsFetchKeysRequestEnd, endRequestReducer('isFetchKeysLoading')],
    [clientsKeyHandlerRequestStart, startRequestReducer('isKeyHandlerLoading')],
    [clientsKeyHandlerRequestEnd, endRequestReducer('isKeyHandlerLoading')],
    [
      clientsSetCurrentClient,
      produce((draft, action) => {
        draft.currentClient = action.payload
      }),
    ],
    [
      clientsSetClientList,
      produce((draft, action) => {
        draft.list = action.payload
      }),
    ],
    [
      clientsSetKeys,
      produce((draft, action) => {
        draft.keys = action.payload
      }),
    ],
    [
      clientsSetPagination,
      produce((draft, action) => {
        draft.pagination.page = action.payload.page
        draft.pagination.rowsPerPage = action.payload.rowsPerPage
        draft.pagination.count = action.payload.count
      }),
    ],
  ]),
  defaultState,
)
